<template>
  <div class="item">
    <div class="left">
      <skeleton-box v-if="loading"
                    :width="this.$store.getters.width > 768 ? '220px' : '100%'"
                    height="220px"/>
      <slot
          v-else
          name="images"
      />
    </div>
    <div class="right">
      <div class="item-top">
        <div class="item-top-left">
          <div class="item-name">
            <skeleton-box v-if="loading"
                          width="160px"
                          height="21px"/>
            <slot
                v-else
                name="name"
            />
            <div class="item-stars">
              <skeleton-box v-if="loading"
                            width="50px"
                            height="12px"/>
              <slot
                  v-else
                  name="stars"
              />
            </div>
          </div>
          <div class="item-position">
            <skeleton-box v-if="loading"
                          width="300px"
                          height="17px"/>
            <slot
                v-else
                name="position"
            />
          </div>
          <div class="item-position-low">
            <skeleton-box v-if="loading"
                          width="150px"
                          height="21px"/>
            <slot
                v-else
                name="address"
            />
          </div>

          <div class="item-additionals">
            <skeleton-box v-if="loading"
                          width="100px"
                          height="20px"
                          margin="0 0 5px 0"/>
            <slot
                v-else
                name="additionals"
            />
          </div>
        </div>
        <div class="item-top-right">
          <skeleton-box v-if="loading && this.$store.getters.width > 768"
                        width="40px"
                        height="50px"/>
          <slot
              v-else
              name="rating"
          />
          <div class="item-prices">
            <div class="item-price">
              <skeleton-box v-if="loading"
                            width="160px"
                            height="40px"/>
              <slot
                  v-else
                  name="price"
              />
            </div>
            <skeleton-box v-if="loading  && this.$store.getters.width > 768"
                          width="170px"
                          height="40px"
                          margin="5px 0 0 0"/>
            <slot
                v-else
                name="button-show"
            />
          </div>
        </div>
      </div>
      <div class="item-bottom">
        <div class="left">
          <skeleton-box v-if="loading"
                        width="160px"
                        height="60px"/>
          <slot
              v-else
              name="room-name"
          />
        </div>
        <div class="right">
          <div class="privileges">
            <skeleton-box v-if="loading"
                          width="150px"
                          height="50px"/>
            <slot
                v-else
                name="privileges"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="share">
      <skeleton-box v-if="loading"
                    width="25px"
                    height="25px"/>
      <slot
          v-else
          name="share"
      />
    </div>
    <skeleton-box v-if="loading"
                  width="30px"
                  height="27px"/>
    <slot
        v-else
        name="follow"
    />
    <div class="rating-mobile">
      <div class="rating-block">
        <skeleton-box v-if="loading"
                      width="40px"
                      height="50px"/>
        <slot
            v-else
            name="follow-mobile"
        />
      </div>
    </div>

  </div>
</template>

<script>
import SkeletonBox from "@/components/loaders/SkeletonBox";
export default {
  name: `hotel-item`,
  components: {SkeletonBox},
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  methods: {}
};
</script>
