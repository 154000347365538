<template>
  <div class="view-map-modal modal">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header-v2">
          <h1 class="title">
            Выберите отель на карте
          </h1>
          <div class="close pointer" @click="close">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_b_631_2916)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
                      fill="#F5F5F5"/>
              </g>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M17.8754 8.34029C17.466 7.93081 16.8065 7.9264 16.4024 8.33045L12.9336 11.7993L9.54098 8.40673C9.1315 7.99725 8.46754 7.99731 8.05799 8.40686C7.64844 8.81641 7.64838 9.48036 8.05787 9.88984L11.4504 13.2824L8.1146 16.6183C7.71056 17.0223 7.71496 17.6818 8.12445 18.0913C8.53393 18.5008 9.19342 18.5052 9.59747 18.1011L12.9333 14.7653L16.326 18.158C16.7355 18.5675 17.3994 18.5674 17.809 18.1579C18.2185 17.7483 18.2186 17.0844 17.8091 16.6749L14.4164 13.2822L17.8853 9.81331C18.2893 9.40927 18.2849 8.74978 17.8754 8.34029Z"
                    fill="black"/>
              <defs>
                <filter id="filter0_b_631_2916" x="-45.7896" y="-45.7896" width="117.579" height="117.579"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feGaussianBlur in="BackgroundImage" stdDeviation="22.8948"/>
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_631_2916"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_631_2916" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div class="modal-body-df">
          <div class="map" v-if="!loading">
            <yandex-map
                :coords="center"
                :settings="settingsMap"
                :scroll-zoom="false"
                :options="options"
                :controls="controls"
                zoom="16"
                @boundschange="sizeChange"
                @click="click"
                style="height: 500px;">
              <ymap-marker v-for="(item, index) in items" :key="index"
                           :marker-id="item.id"
                           :icon="{
                             layout: 'default#imageWithContent',
                             imageHref: '',
                             imageSize: [63, 30],
                             imageOffset: [0, 0],
                             content: item.min_price,
                             contentOffset: [0, 0],
                             contentLayout: `<div class='marker-icon'>${item.min_price.toLocaleString('RU-ru')} &#8381;</div>`
                           }"
                           :coords="[item.latitude, item.longitude]"
                           :balloon-template="balloonTemplate[index]"
              />
            </yandex-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {yandexMap, ymapMarker} from 'vue-yandex-maps'

export default {
  name: 'view-map-modal',
  components: {yandexMap, ymapMarker},
  data: function () {
    return {
      loading: false,
      items: [],
      data: [],
      settingsMap: {
        apiKey: '',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
      },
      center: [44.89427, 37.316887],
      options: {
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
      },
      controls: ['geolocationControl', 'zoomControl']
    }
  },
  computed: {
    getNights: function () {
      let diff = this.$core.diffDates(this.data.date[0], this.data.date[1])
      return diff + ' ' + this.$core.declination(diff, ['ночь', 'ночи', 'ночей'])
    },
    getRating: function () {
      return this.items.map(function (item) {
        let rating = ``
        for (let i = 0; i < item.star_rating; i++) {
          rating += `<img src="${require('@/assets/images/catalog/star.png')}">`
        }

        return rating
      })
    },
    getImage: function () {
      return this.items.map(function (item) {
        if (item.images.length > 0) {
          return `<img class="balloon-img" src="${item.images[0].url.replace('{size}', '240x240')}">`
        } else {
          return `<img class="balloon-img" src="${require('@/assets/images/catalogs/hotels/hotel-no-image-mini-2.png')}">`
        }
      })
    },
    balloonTemplate() {
      let vm = this
      let night = vm.getNights
      let rating = vm.getRating
      let image = vm.getImage
      return this.items.map(function (item, index) {
        return `<div class="balloon-template">
<a class="balloon"
        href="/hotel/${item.id}?id=${item.id}&dateArrival=${vm.data.date[0]}&dateDeparture=${vm.data.date[1]}&adults=${vm.data.adults}"
        target="_blank">
            ${image[index]}
            <div class="ratings">
                <div class="star_rating">
                    ${rating[index]}
                </div>
                <div class="rating">
                    <img src="${require('@/assets/images/catalog/star.png')}">
                    <span>${item.rating}</span>
                </div>
            </div>
            <h3>${item.name}</h3>
            <p><span class="price">${item.min_price.toLocaleString('RU-ru')} &#8381; </span> за ${night}, за номер</p>
        </a></div>`
      })
    }
  },
  mounted() {
    let vm = this
    this.$nextTick(() => {
      this.$root.$on('dataMap', (val) => {
        vm.center = [val.coordinates.latitude, val.coordinates.longitude]
        vm.data = val
        vm.data.radius = 500
        vm.fetchGeo()
      });
    })
  },
  methods: {
    click: function (e) {
      e.get('target').balloon.close()
    },
    sizeChange: function (e) {
      let startZoom = 16
      let maxRadius = 2000
      let newZoom = e.get('newZoom')
      let oldZoom = e.get('oldZoom')
      let oldCenter = e.get('oldCenter')
      let newCenter = e.get('newCenter')

      if (oldCenter[0] !== newCenter[0] || oldCenter[1] !== newCenter[1]) {
        this.data.coordinates.latitude = newCenter[0]
        this.data.coordinates.longitude = newCenter[1]
        this.fetchGeo(false)
      }

      if (newZoom < oldZoom && newZoom < startZoom) {
        this.data.radius = this.data.radius * 2
        if (this.data.radius <= maxRadius) {
          this.fetchGeo(false)
        }
      }

      if (newZoom >= startZoom && this.data.radius !== 500) {
        this.data.radius = 500
        this.fetchGeo(false)
      }
    },
    fetchGeo: function (loading = true) {
      this.loading = loading
      let filters = {
        name: this.data.filters.name,
        stars: this.data.filters.stars,
        freeCancellation: this.data.filters.freeCancellation,
        meal: this.data.filters.meal,
        price: this.data.filters.price
      }

      if (filters.stars.max === 1) {
        filters.stars.max = 2
      }

      let data = {
        id: this.data.id,
        type: this.data.type,
        dateArrival: this.data.date[0],
        dateDeparture: this.data.date[1],
        adults: this.data.adults,
        children: this.data.ages,
        latitude: this.data.coordinates.latitude,
        longitude: this.data.coordinates.longitude,
        radius: this.data.radius,
        // eslint-disable-next-line no-undef
        filters: _.omitBy(filters, _.isEmpty),
      }

      this.$store.dispatch('getVariantsByGeo', data).then((res) => {
        if (res.data.result === 'ok') {
          this.items = res.data.data
        }
        this.loading = false
      })
    },
    close: function () {
      this.$root.$emit('openModal', 'viewMap');
    },
  }
}
</script>

<style>
.balloon {
  margin-top: 20px;
  cursor: pointer;
}

.balloon .balloon-img {
  width: 240px;
  height: 130px;
  border-radius: 10px;
}

.balloon .ratings {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.balloon .ratings .star_rating {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.balloon .ratings .star_rating img {
  width: 12px;
  height: 12px;
  margin-right: 3px;
}

.balloon .ratings .rating {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.balloon .ratings .rating img {
  width: 12px;
  height: 12px;
  margin-right: 3px;
}

.balloon .ratings .rating span {
  font-weight: bold;
}

.balloon .price {
  font-family: dinpro, serif;
  font-weight: bold;
  font-size: 16px;
}

.marker-icon {
  padding: 2px 8px 1px 8px;
  white-space: nowrap;
  width: 63px;
  text-align: center;
  font-family: 'dinpro', serif;
  font-weight: bold;
  color: #FFFFFF;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: #005bff;
  box-shadow: 0 6px 16px 2px rgba(0, 26, 52, 0.16), 0 0 0 1px rgba(0, 26, 52, 0.05);
}

.balloon-template .balloon h3 {
  width: 200px;
}

</style>
