<template>
  <div class="layer-mobile">
    <div class="layer-header">
      <div class="close" @click="close">
        <img src="../../../assets/images/arrows/arrow-left.svg"/>
      </div>
      <div class="input-placeholder">
        <label class="placeholder" v-if="query.length > 0">Куда вы хотите поехать?</label>
        <input
            type="text"
            v-model="query"
            placeholder="Куда вы хотите поехать?"
            @keyup="search"/>
        <input-loader :loading="loading" :css-top="20"/>
      </div>
    </div>
    <auto-compete :hotels="hotel ? hotels : []" :regions="regions"/>
  </div>
</template>

<script>
import AutoCompete from "@/components/AutoCompete/hotel/AutoCompete.vue";
import InputLoader from "@/components/loaders/input/loader.vue";

export default {
  name: 'AutoCompeteMobile',
  components: {InputLoader, AutoCompete},
  props: {
    hotel: {
      type: Boolean,
      default: true,
    }
  },
  data: function () {
    return {
      query: '',
      hotels: [],
      regions: [],
      timer: null,
      loading: false,
    }
  },
  methods: {
    search: function () {
      if (!this.timer) {
        if (this.query.length > 1) {
          this.loading = true
          this.timer = setTimeout(() => {
            this.$store.dispatch('getAutoCompleteRegionAndHotel', {
              query: this.query
            }).then((res) => {
              this.hotels = res.data.data.hotels
              this.regions = res.data.data.regions
              this.$store.state.searchTrigger = true
              this.timer = null
              this.loading = false
            }).catch(() => {
              this.$toast.error('Ошибка... Повторите поиск')
              this.timer = null
              this.loading = false
              this.$store.state.searchTrigger = false
            })
          }, 500)
        }
      }
    },
    close: function () {
      this.query = ''
      this.$store.state.autoCompleteMobile = false
    }
  }
}
</script>

<style scoped>
.layer-mobile {
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: #FFFFFF;
}

.layer-mobile .layer-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid rgba(0, 91, 255, 0.5);
}

.layer-mobile .layer-header .close {
  width: 24px;
  height: 24px;
  padding-left: 15px;
}

.layer-mobile .layer-header .close img {
  width: 24px;
  height: 24px;
}

.layer-mobile .layer-header .input-placeholder {
  position: relative;
  padding: 15px 15px;
}

.layer-mobile .layer-header .input-placeholder label.placeholder {
  position: absolute;
  bottom: auto;
  left: 25px;
  right: auto;
  top: 0;
  font-size: 13px;
  transform: translateY(0);
  transition: all .3s ease;
}

.layer-mobile .layer-header .input-placeholder {
  margin-left: 10px;
  width: 100%;
}

.layer-mobile .layer-header .input-placeholder input {
  border: none;
  font-size: 18px;
  width: 100%;
}

</style>
