<template>
  <div class="catalog-mobile-search">
    <div class="catalog-mobile-search-header">
      <svg width="89" height="3" viewBox="0 0 89 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="89" height="3" rx="1.5" fill="black"/>
      </svg>
      <div class="toggle-filter" @click="$root.$emit('triggerCatalogSearchMobile')">
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M9.88111 7.99886L15.6089 2.28357C15.8597 2.03271 16.0007 1.69246 16.0007 1.33769C16.0007 0.98291 15.8597 0.642664 15.6089 0.391799C15.3581 0.140935 15.0179 0 14.6632 0C14.3084 0 13.9682 0.140935 13.7174 0.391799L8.00293 6.12041L2.28845 0.391799C2.03762 0.140935 1.69743 -2.64329e-09 1.3427 0C0.987973 2.64329e-09 0.647776 0.140935 0.396947 0.391799C0.146117 0.642664 0.00520296 0.98291 0.00520296 1.33769C0.00520295 1.69246 0.146117 2.03271 0.396947 2.28357L6.12474 7.99886L0.396947 13.7142C0.272096 13.838 0.173 13.9853 0.105374 14.1477C0.0377473 14.31 0.00292969 14.4842 0.00292969 14.66C0.00292969 14.8359 0.0377473 15.01 0.105374 15.1724C0.173 15.3347 0.272096 15.4821 0.396947 15.6059C0.520778 15.7308 0.668103 15.8299 0.830425 15.8975C0.992748 15.9652 1.16685 16 1.3427 16C1.51855 16 1.69265 15.9652 1.85497 15.8975C2.0173 15.8299 2.16462 15.7308 2.28845 15.6059L8.00293 9.87731L13.7174 15.6059C13.8412 15.7308 13.9886 15.8299 14.1509 15.8975C14.3132 15.9652 14.4873 16 14.6632 16C14.839 16 15.0131 15.9652 15.1754 15.8975C15.3378 15.8299 15.4851 15.7308 15.6089 15.6059C15.7338 15.4821 15.8329 15.3347 15.9005 15.1724C15.9681 15.01 16.0029 14.8359 16.0029 14.66C16.0029 14.4842 15.9681 14.31 15.9005 14.1477C15.8329 13.9853 15.7338 13.838 15.6089 13.7142L9.88111 7.99886Z"
              fill="black"/>
        </svg>
      </div>
    </div>
    <div class="catalog-mobile-search-body">
      <div class="main-search">
        <div class="container">
          <div class="search-inputs">
            <div class="towns-search">

              <input
                  class="search-type"
                  type="text"
                  placeholder="Город или отель"
                  v-model="data.text"
                  @click="$store.state.autoCompleteMobile = true; $event.target.blur()"/>
            </div>

            <div class="date-pickers">
              <div class="calendar">
                <date-picker2 range v-model="data.date" placeholder="Даты бронирования" :format="'YYYY-MM-DD'"
                              :value-type="'YYYY-MM-DD'" :disabled-date="$core.disabledDate" :clearable="false"/>
              </div>
            </div>

            <div class="form-group">
              <label
                  class="label"
                  @click="$store.state.selectPlacement = !$store.state.selectPlacement">
                Размещение
              </label>
              <input
                  type="text"
                  class="search-room"
                  v-model="selectedRoom"
                  :readonly="true"
                  @click="$store.state.selectPlacement = !$store.state.selectPlacement">
              <transition name="fade">
                <div class="select-placement" v-if="$store.state.selectPlacement">
                  <div class="placement-item">
                    <div class="placement-item-titles">
                      <div class="placement-item-title">Взрослые</div>
                      <div class="placement-item-sub-title">старше 17 лет</div>
                    </div>
                    <div class="placement-item-counter">
                      <button class="btn" @click="updateCounter('adults', 0)" :disabled="data.adults < 2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                          <path fill="currentColor" d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                        </svg>
                      </button>
                      <input class="counter" v-model="data.adults" :disabled="true"/>
                      <button class="btn" @click="updateCounter('adults', 1)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                          <path fill="currentColor"
                                d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="placement-item">
                    <div class="placement-item-titles">
                      <div class="placement-item-title">Дети</div>
                      <div class="placement-item-sub-title">от 0 до 17 лет</div>
                    </div>
                    <div class="placement-item-counter">
                      <button class="btn" @click="updateCounter('children', 0)" :disabled="data.children < 1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                          <path fill="currentColor" d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                        </svg>
                      </button>
                      <input class="counter" v-model="data.children" :disabled="true"/>
                      <button class="btn" @click="updateCounter('children', 1)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                          <path fill="currentColor"
                                d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="placement-item child-age" v-if="data.children > 0">
                    <multiselect
                        v-for="index in data.children"
                        :key="index"
                        v-model="childrenAges[index - 1]"
                        track-by="name"
                        label="name"
                        :options="options"
                        :searchable="false"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="Укажите возраст"
                        :openDirection="'below'"
                    ></multiselect>
                  </div>
                </div>
              </transition>
            </div>

            <button class="btn btn-primary search-hotel" @click="startSearch">Найти отель</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker2 from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import qs from "qs";

export default {
  name: 'catalog-mobile-search',
  components: {DatePicker2},
  data: function () {
    return {
      data: {
        text: '',
        type: 0,
        id: 0,
        date: [],
        adults: 1,
        children: 0,
        ages: [],
        limit: 12,
        offset: 0,
      },
      childrenAges: [],
      options: [
        {name: 'До года', code: '0'},
        {name: '1 год', code: '1'},
        {name: '2 года', code: '2'},
        {name: '3 года', code: '3'},
        {name: '4 года', code: '4'},
        {name: '5 лет', code: '5'},
        {name: '6 лет', code: '6'},
        {name: '7 лет', code: '7'},
        {name: '8 лет', code: '8'},
        {name: '9 лет', code: '9'},
        {name: '10 лет', code: '10'},
        {name: '11 лет', code: '11'},
        {name: '12 лет', code: '12'},
        {name: '13 лет', code: '13'},
        {name: '14 лет', code: '14'},
        {name: '15 лет', code: '15'},
        {name: '16 лет', code: '16'},
        {name: '17 лет', code: '17'}
      ]
    }
  },
  watch: {
    childrenAges(newValue) {
      this.data.ages = []
      Object.keys(newValue).forEach((index) => {
        this.data.ages.push(this.childrenAges[index].code)
      })
    }
  },
  async mounted() {
    const q = qs.parse(this.$route.query)

    this.data.id = q.id
    this.data.text = q.text
    this.data.type = q.type
    this.data.date.push(q.dateArrival)
    this.data.date.push(q.dateDeparture)
    this.data.adults = q.adults

    if (q.children) {
      this.data.children = q.children.length
      this.data.ages = q.children
      this.setAges()
    }

    this.$root.$on('autocomplete', (val) => {
      this.setAutoComplete(val)
    });
  },
  computed: {
    selectedRoom: function () {
      let totalGuests = parseInt(this.data.adults) + parseInt(this.data.children);
      return totalGuests + ' ' + this.$core.declination(totalGuests, ['гость', 'гостя', 'гостей'])
    },
  },
  methods: {
    setAges: function () {
      if (this.data.ages.length > 0) {
        this.data.ages.forEach((index) => {
          this.childrenAges.push(this.options[index])
        })
      }
    },
    setAutoComplete: function (val) {
      this.data.id = val.id
      this.data.text = val.name
      this.data.type = val.type
    },
    updateCounter: function (variable, type) {
      if (type === 0 && variable === 'adults' && this.data[variable] > 1) {
        this.data[variable]--;
      } else if (type === 0 && variable === 'children' && this.data[variable] >= 1) {
        this.data[variable]--;
        this.childrenAges.pop();
      } else if (variable === 'children' && this.data[variable] < 4) {
        this.data[variable]++;
      } else if (variable !== 'children' && this.data[variable] < 6) {
        this.data[variable]++;
      }
    },
    startSearch: function () {
      this.$root.$emit('triggerCatalogSearchMobile')
      this.$root.$emit('startSearch', this.data)
    }
  }
}
</script>
